<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav" :style="{ marginLeft: this.$sidebar.isMinimized ? '16rem' : '0%' }">
        <li class="nav-item">
          <a href="https://sax-power.net/kontakt/" target="_blank" rel="noopener" class="nav-link">
            {{ $t('login.contact') }}
          </a>
        </li>
        <li class="nav-item">
          <a href="https://sax-power.net/impressum/" target="_blank" rel="noopener" class="nav-link">
            {{ $t('login.imprint') }}
          </a>
        </li>
        <li class="nav-item">
          <a href="https://sax-power.net/datenschutzerklaerung/" target="_blank" rel="noopener" class="nav-link">
            {{ $t('login.PRIVACYPOLICY') }}
          </a>
        </li>
      </ul>
      <div class="copyright">
        &copy; {{ year }}, made with <i class="tim-icons icon-heart-2"></i> by
        <a target="_blank" rel="noopener">SAX POWER GmbH</a>
        .
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
