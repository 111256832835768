<template>
  <div />
</template>

<script>

import { useAuth } from '@/modules/auth'
// import router from '@/router'

export default {
  name: 'Logout',
  mounted() {
    useAuth.logout().then(() => {
      this.$router.push('/login').catch(() => {
        window.location.reload();
      })
    })
  }
}
</script>
