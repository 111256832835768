<template>
  <router-view></router-view>
</template>

<script>
import { TokenStorage } from "/src/modules/token-storage.js";
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    }
  },
  async mounted() {
    if (TokenStorage.isAuthenticated() && window.sessionStorage.length == 0) this.$router.push("/logout");
    // if (window.sessionStorage.length == 0) {//this.$router.push("/logout");
    //   await this.$store.dispatch("get_sns").then(() => {                    ##################remember Me
    //     window.location.reload();
    //   });
    //   window.location.reload();
    //   console.log("-*----------*--**-**--------------------------*-*-*-*-*-*-*-*");
    // }
    this.initializeLayout();
    // this.$store.dispatch("resetDarkMode");
  }
};
</script>