import axios from 'axios'

const ACCESS_TOKEN_STORE = process.env.VUE_APP_TOKEN_ACCESS_KEY
const REFRESH_TOKEN_STORE = process.env.VUE_APP_TOKEN_REFRESH_KEY

// const isAuthenticated() {
// 	return getAccessToken() !== null
// }
export const TokenStorage = {

	getAuthorizationHeader() {
		if (this.getAccessToken()) {
			return {
				headers: {'Authorization': 'Bearer ' + this.getAccessToken()},
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		} else {
			return {}
		}
	},
	getNewToken() {
		return new Promise((resolve, reject) => {
			axios
				.post(process.env.VUE_APP_API_ENDPOINT + '/api/auth/token/refresh/', {refresh: this.getRefreshToken()})
				.then(response => {

					this.storeAccessToken(response.data.access)
					// this.storeRefreshToken(response.data.refresh)

					resolve(response.data.access)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	isAuthenticated() {
		return localStorage.getItem(ACCESS_TOKEN_STORE) !== null
	},
	storeAccessToken(token) {
		localStorage.setItem(ACCESS_TOKEN_STORE, token)
	},
	storeRefreshToken(refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_STORE, refreshToken)
	},
	getAccessToken() {
		return localStorage.getItem(ACCESS_TOKEN_STORE)
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_STORE)
	},
	clear() {
		localStorage.removeItem(ACCESS_TOKEN_STORE)
		localStorage.removeItem(REFRESH_TOKEN_STORE)
	}
}
