<template>
  <!-- <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">{{ $t('FixedSettings.SidebarBackground') }}
        </li>
        <li class="adjustments-line">
          <a class="switch-trigger background-color">
            <div class="badge-colors text-center">
              <span
                v-for="item in sidebarColors"
                :key="item.color"
                class="badge filter"
                :class="[`badge-${item.color}`, { active: item.active }]"
                :data-color="item.color"
                @click="changeSidebarBackground(item);"
              ></span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

        <li class="header-title">{{$t('FixedSettings.SidebarMini')}}</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span class="label-switch">{{$t('FixedSettings.off')}}</span>
            <base-switch
              v-model="sidebarMini"
              @input="minimizeSidebar"
            ></base-switch>
            <span class="label-switch label-right">{{$t('FixedSettings.on')}}</span>
          </div>

          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch">{{$t('FixedSettings.LIGHTMODE')}}</span>
            <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
            <span class="label-switch label-right">{{$t('FixedSettings.DARKMODE')}}</span>
          </div>
          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch">DE</span>
            <base-switch v-model="selectedLanguage" @input="ChangeLanguage"></base-switch>
            <span class="label-switch label-right">EN</span>
          </div>
        </li>

        <li class="button-container mt-5">
         
        </li>
      </ul>
    </div>
  </div> -->
</template>
<script>
  import { BaseSwitch } from 'src/components';
  import { store } from "@/store";


  export default {
    name: 'sidebar-share',
    components: {
      BaseSwitch
    },
    // props: {
    //   backgroundColor: String
    // },
    data() {
      return {
        selectedLanguage: true,
        sidebarMini: true,
        darkMode: true,
        isOpen: false,
        sidebarColors: [
          { color: 'primary', active: false, value: 'primary' },
          { color: 'vue', active: true, value: 'vue' },
          
          
        ]
      };
    },

    methods: {
      toggleDarkMode() {
      const darkMode = this.$store.getters.getDarkMode;

      
      this.$store.commit('setDarkMode', !darkMode);
    },
      toggleDropDown() {
        this.isOpen = !this.isOpen;
      },
      closeDropDown() {
        this.isOpen = false;
      },
      toggleList(list, itemToActivate) {
        list.forEach(listItem => {
          listItem.active = false;
        });
        itemToActivate.active = true;
      },
      changeSidebarBackground(item) {
        this.$emit('update:backgroundColor', item.value);
        this.toggleList(this.sidebarColors, item);
      },
      ChangeLanguage() {
        if(this.selectedLanguage){
          this.$i18n.locale = "en";
          
         
        }else{
          this.$i18n.locale = "de";
          
        }
      },
      toggleMode(type) {
        this.toggleDarkMode();
        let docClasses = document.body.classList;
        if (type) {
          
          docClasses.remove('white-content');
        } else {
         
          
          docClasses.add('white-content');
        }
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize();
      }
    }
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

  .settings-icon {
    cursor: pointer;
  }

  .badge-vue {
    background-color: $vue;
  }
</style>
