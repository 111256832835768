import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const dateTimeFormats = {
  en: {

    quarter: {
      hour: "numeric",
      
    },
    day: {
      hour: "numeric",
      hour12: false,
    },
    month: {
      day: "numeric",
    },
    year: {
      month: "short",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
    year_time: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      second: "numeric",
      fractionalSecondDigits: 2,
    },
    year_time_short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
    // short: {
    // 	year: 'numeric', month: 'short', day: 'numeric'
    // },
    // long: {
    // 	year: 'numeric', month: 'short', day: 'numeric',
    // 	weekday: 'short', hour: 'numeric', minute: 'numeric'
    // }
  },
  de: {
    quarter: {
      hour: "numeric",
      hour12: false,
    },
    day: {
      hour: "numeric",
      hour12: false,
    },
    month: {
      day: "numeric",
    },
    year: {
      month: "short",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
    year_time: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      second: "numeric",
      fractionalSecondDigits: 2,
    },
    year_time_short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
    // short: {
    // 	year: 'numeric', month: 'short', day: 'numeric'
    // },
    // long: {
    // 	year: 'numeric', month: 'short', day: 'numeric',
    // 	weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    // }
  },
};
const browserLang = navigator.language.split("-", 1)[0];

export default new VueI18n({
  dateTimeFormats,
  browserLang,
  locale:'de',
  fallbackLocale:'de',
  messages: loadLocaleMessages()
});
