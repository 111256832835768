import axios from "axios";
import { store } from "../store/index";
import { TokenStorage } from "./token-storage";
import { useAuth } from "./auth";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status !== 401 || !TokenStorage.getRefreshToken()) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    } else {


      TokenStorage.getNewToken()
        .then((token) => {
          // console.log(token);

          // location.reload();

        })
        .catch((error) => {
          console.log(error);
          useAuth.logout().then(() => {
            this.$router.push("/login").catch(() => { });
          });
          location.reload();
        });
    }
  }
);

export const transformRequest = (jsonData = {}) =>
  Object.entries(jsonData)
    .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join("&");

export const useApi = {
  post:async (endpoint, payload) => {
    const config = TokenStorage.getAuthorizationHeader();
    // console.log("config:::: ", config);
    store.commit("startLoading");

    return await axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + endpoint,
        transformRequest(payload),
        config
      )
      .then((response) => {
        //console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("error:::: ", error);
        store.commit("setErrorFields", error?.response?.data);
        throw error.response.data;
      })
      .finally(() => {
        store.commit("stopLoading");
      });
  },
  get: async (endpoint, params) => {
    const config = { ...params, ...TokenStorage.getAuthorizationHeader() };
    store.commit("startLoading");

    return await axios
      .get(process.env.VUE_APP_API_ENDPOINT + endpoint, config)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .catch((error) => {
        //console.log(error.response);
        store.commit("setErrorFields", error.response.data);
        throw error.response.data;
      })
      .finally(() => {
        store.commit("stopLoading");
      });
  },
};