export default {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        const method = vnode.context[binding.expression];
        if (typeof method === 'function') {
          method(event);
          console.log('Expression:', binding.expression);
          console.log('Context:', vnode.context);
          console.log('Method:', method);
        } else {
          console.error(`Method ${binding.expression} is not defined`);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
};
